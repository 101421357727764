<template>
  <div class="dashboard">
    <div class="card">
      <div class="card-header">
        <div class="d-flex">
          <h2 class="mr-auto">{{ $trans('Dashboard') }}</h2>
          <div class="d-flex flex-row-reverse">
            <select v-model="filterQuery.filterby" class="custom-select w150" @change="triger_filter">
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="month">This Month</option>
              <option value="last_month">Last Month</option>
              <option value="year">This Year</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-sm-7">
            <div class="row">
              <div class="col-sm-4">
                <div class="box-count">
                  <div class="icon bg-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-activity">
                      <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                    </svg>
                  </div>
                  <div class="content">
                    <h6>{{ $trans('Revenue') }}</h6>
                    <span>
                                <vue-numeric
                                    currency-symbol-position="suffix"
                                    v-bind:currency="current_currency"
                                    v-bind:value="report_data.revenue"
                                    v-bind:read-only="true"
                                    v-bind:precision="0">
                                </vue-numeric>
                            </span>

                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="box-count">
                  <div class="icon bg-info">
                    <svg data-v-7d622f5c="" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2"
                         fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <polygon data-v-7d622f5c="" points="12 2 2 7 12 12 22 7 12 2"></polygon>
                      <polyline data-v-7d622f5c="" points="2 17 12 22 22 17"></polyline>
                      <polyline data-v-7d622f5c="" points="2 12 12 17 22 12"></polyline>
                    </svg>
                  </div>
                  <div class="content">
                    <h6>{{ $trans('Transactions') }}</h6>
                    <span>
                                 <vue-numeric class='tex'
                                              currency-symbol-position="suffix"
                                              currency=""
                                              v-bind:value="report_data.total_transaction"
                                              v-bind:read-only="true"
                                              v-bind:precision="0">
                                </vue-numeric>
                            </span>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="box-count">
                  <div class="icon bg-orange">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                         stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <line x1="12" y1="1" x2="12" y2="23"></line>
                      <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                    </svg>
                  </div>
                  <div class="content">
                    <h6>{{ $trans('Fee') }}</h6>
                    <span>
                                <vue-numeric
                                    currency-symbol-position="suffix"
                                    v-bind:currency="current_currency"
                                    v-bind:value="report_data.fee"
                                    v-bind:read-only="true"
                                    v-bind:precision="0">
                                </vue-numeric>
                            </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="margin-bottom: 30px">
              <slot v-if="results && results.length && transaction_revenue_chart">
                <GChart
                    type="LineChart"
                    :data="report_data.revenue_chart_data"
                    :options="transaction_revenue_chart.options"
                />
              </slot>
              <slot v-else>
                {{ $trans('No data') }}
              </slot>
            </div>
          </div>
          <div class="col-sm-3">
            <widget-ib :filterby="filterQuery.filterby"></widget-ib>
            <widget-momo-report :filterby="filterQuery.filterby"></widget-momo-report>
            <widget-zalo-report :filterby="filterQuery.filterby"></widget-zalo-report>
          </div>
          <div class="col-sm-2">
            <widget-ib-online></widget-ib-online>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Dashboard",
  data: function () {
    return {
      user: null,
      filterQuery: {
        filterby: 'today'
      },
      current_currency: this.$root.current_currency,
      statics_data: null,
      transaction_revenue_chart: null,
      results: null,
      amountWithdrawal: null,
    }
  },
  methods: {
    triger_filter: function () {
      this.get_statistic();
    },
    get_statistic: function () {
      axios({url: this.$root.$data.api_url + '/api/client/statistic', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.statics_data = resp.data.data;
            this.results = resp.data.results;
            this.amountWithdrawal = resp.data.withdrawal;
            this.transaction_revenue_chart = resp.data.transaction_revenue_chart;
          })
          .catch(err => {
            console.log(err)
          })
    }
  },
  created() {
    this.triger_filter();
  },
  computed: {
    report_data: function () {
      if (!this.results) {
        return [];
      }

      let data = this.results
      let revenue_chart_data = this.transaction_revenue_chart.data;
      let total_transaction = 0;
      let fee = 0;
      let revenue = 0;
      let totalWithdrawal = 0;
      let totalTransactionsWithdrawal = 0;
      data.forEach((el) => {
        total_transaction = total_transaction + el.total_transaction;
        fee = fee + el.fee
        revenue = revenue + el.revenue
        let item_revenue_chart_data = [
          el.vAxisTitle,
          el.revenue,
          el.total_transaction,
        ]
        revenue_chart_data.push(item_revenue_chart_data);

      });



      return {
        revenue_chart_data: revenue_chart_data,
        revenue: revenue,
        total_transaction: total_transaction,
        totalWithdrawal: totalWithdrawal,
        totalTransactionsWithdrawal:totalTransactionsWithdrawal,
        fee: fee,
      }

    }

  }
}
</script>

<style scoped>

</style>