<template>
    <div class="settings">
      <div class="card">
          <div class="card-header">
            <h2 class="mr-auto">{{$trans('Settings')}}</h2>
          </div>
        <nav>
          <div class="nav nav-tabs" id="nav-tab" >
            <a class="nav-item nav-link" @click="tabActive = 'system'" v-bind:class="{'active':tabActive=='system'}" href="javascript:void(0)">{{$trans('System')}}</a>
            <a class="nav-item nav-link" @click="tabActive = 'banks'" v-bind:class="{'active':tabActive=='banks'}" href="javascript:void(0)">{{$trans('Payout Banks')}}</a>
            <a class="nav-item nav-link" @click="tabActive = 'api'" v-bind:class="{'active':tabActive=='api'}" href="javascript:void(0)">{{$trans('API')}}</a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div v-if="tabActive == 'system'" class="tab-pane active">
            <div class="card">
                <div class="card-body">
                  Coming soon!
                </div>
            </div>
          </div>
          <div v-if="tabActive == 'banks'" class="tab-pane active">
            <banks-settings></banks-settings>
          </div>
          <div v-if="tabActive == 'api'" class="tab-pane active">
            <api-setting></api-setting>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "Settings",
        data:function () {
            return {
                tabActive:'system'
            }
        }
    }
</script>

<style scoped>

</style>