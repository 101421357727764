<template>
  <div class="card">
    <div class="card-header">
      <h2 class="mr-auto">{{$trans('Reports')}}</h2>
    </div>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" >
        <a class="nav-item nav-link" @click="tabActive = 'ib'" v-bind:class="{'active':tabActive=='ib'}" href="javascript:void(0)">{{$trans('Internet Banking')}}</a>
        <a class="nav-item nav-link" @click="tabActive = 'report-momo'" v-bind:class="{'active':tabActive=='report-momo'}" href="javascript:void(0)">{{$trans('MoMo')}}</a>
        <a class="nav-item nav-link" @click="tabActive = 'report-zalo'" v-bind:class="{'active':tabActive=='report-zalo'}" href="javascript:void(0)">{{$trans('Zalo')}}</a>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div v-if="tabActive == 'ib'" class="tab-pane active">
        <report-ib></report-ib>
      </div>
      <div v-if="tabActive == 'report-momo'" class="tab-pane active">
        <report-momo></report-momo>
      </div>
      <div v-if="tabActive == 'report-zalo'" class="tab-pane active">
        <report-zalo></report-zalo>
      </div>

    </div>
  </div>
</template>

<script>
export default {
name: "Reports",
  data:function (){
    return {
      tabActive:'report-momo'
    }
  }
}
</script>

<style scoped>

</style>