<template>
    <div class="profile">
        <div class="card">
            <div class="card-header">
                <h2>{{$trans('My Profile')}}</h2>
            </div>
            <div class="card-body">
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                    {{alert.message}}
                </div>
                <div class="form-group">
                    <label>{{$trans('Full name')}}</label>
                    <input class="form-control" type="text" v-model="profile.name">
                    <slot v-if="errors && errors.name">
                        <div class="text-danger" v-for="error in errors.name" v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
              <div class="form-group">
                <label>{{$trans('Phone')}}</label>
                <input class="form-control" type="text" v-model="profile.phone">
                <slot v-if="errors && errors.phone">
                  <div class="text-danger" v-for="error in errors.phone" v-bind:key="error">
                    <small>{{ error }}</small>
                  </div>
                </slot>
              </div>
                <div class="form-group">
                    <label>{{$trans('Current password')}}</label>
                    <input v-model="profile.current_password" type="password" class="form-control">
                    <slot v-if="errors && errors.current_password">
                        <div class="text-danger" v-for="error in errors.current_password"
                             v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <div class="form-group">
                    <label>{{$trans('New password')}}</label>
                    <input v-model="profile.password" type="password" class="form-control">
                    <slot v-if="errors && errors.password">
                        <div class="text-danger" v-for="error in errors.password" v-bind:key="error">
                            <small>{{ error }}</small>
                        </div>
                    </slot>
                </div>
                <button @click="save_profile" class="btn btn-primary">{{$trans('Save')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "Profile",
        data: function () {
            return {
                user: {},
                profile: {
                    name: '',
                    phone: '',
                    current_password: '',
                    password: ''
                },
                process: false,
                alert: null,
                errors: {}
            }
        },
        methods: {
            get_info: function () {
                axios({url: this.$root.$data.api_url + '/api/profile', method: 'GET'})
                    .then(resp => {
                        this.user = resp.data.user;
                        this.profile.name = resp.data.user.name
                        if( resp.data.user.phone){
                            this.profile.phone = resp.data.user.phone;
                        }

                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            save_profile: function () {
                if (this.process == false) {
                    this.process = true;
                    this.errors = {}
                    this.alert = null
                    axios({url: this.$root.$data.api_url + '/api/profile',data:this.profile, method: 'POST'})
                        .then(resp => {
                            this.process = false;
                            const error_code = resp.data.error_code;
                            const user = resp.data.user
                            if (error_code == 0) {
                                this.alert = {
                                    type: 'success',
                                    message: resp.data.message
                                }
                                this.$store.commit('update_profile', user.name)
                            } else {
                                this.alert = {
                                    type: 'warning',
                                    message: resp.data.message
                                }
                                this.errors = resp.data.errors || {};

                            }
                        })
                        .catch(err => {
                            this.process = false;
                            console.log(err)
                        })
                }
            }
        },
        created() {
            this.get_info();
        }
    }
</script>

<style scoped>

</style>