<template>
  <div class="deposit-banks">
    <div class="card">
      <div class="card-body">
        <div class="card-header d-flex">
            <h2 class="mr-auto ">{{$trans('Your banks')}}</h2>
            <button @click="addForm = true" class="btn btn-primary btn-sm">
              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                  stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line>
                <line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              {{$trans('Add new')}}
            </button>
          </div>
        <slot v-if="withdrawl_banks && withdrawl_banks.data">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>{{$trans('Bank Name')}}</th>
                <th>{{$trans('Swift Code')}}</th>
                <th>{{$trans('Bank Info')}}</th>
                <th>{{$trans('Status')}}</th>
                <th>{{$trans('Actions')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(m,index) in withdrawl_banks.data" v-bind:key="index">
                <td>{{m.name}}</td>
                <td>{{ m.swiftcode }}</td>
                <td>{{m.account_name}} - {{ m.account_number }}</td>
                <td>
                  <span v-if="m.status !== 1 " class="stt-lock">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                  </span>
                  <span v-else class="stt-unlock">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg>
                  </span>
                </td>
                <td>
                  <div class="table-controls">
                    <a @click="destroy(m)" class="text-danger"
                      href="javascript:void(0)">
                      <svg data-v-7db115a6="" xmlns="http://www.w3.org/2000/svg"
                          width="20" height="20" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="feather feather-trash-2">
                        <polyline data-v-7db115a6="" points="3 6 5 6 21 6"></polyline>
                        <path data-v-7db115a6=""
                              d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line data-v-7db115a6="" x1="10" y1="11" x2="10" y2="17"></line>
                        <line data-v-7db115a6="" x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </a>
                  </div>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        <paginate v-if="withdrawl_banks && withdrawl_banks.data && withdrawl_banks.last_page"
            :page-count="withdrawl_banks.last_page"
            :click-handler="paginate"
            :prev-text="$trans('Previous')"
            :next-text="$trans('Next')"
            :page-class="'page-item'"
            :prev-class="'page-item'"
            :next-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-link-class="'page-link'"
            :next-link-class="'page-link'"
            :container-class="'pagination'">
        </paginate>
        </slot>
        <slot v-else>
            {{$trans('No data')}}
        </slot> 
      </div>
    </div>
    <modal v-if="addForm" v-bind:width="'500px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Add a Bank Account')}}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2"
                 fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{$trans('The safety and security of your bank account information is protected by pcash0.')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Bank name')}} (*)</label>
          <Select2 v-model="addFields.bank_code"  :options="payout_bank_supports"  />
          <slot v-if="errors && errors['bank_code']">
            <div class="text-danger" v-for="error in errors['bank_code']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('SWIFT code')}} (*)</label>
          <input v-model="addFields.swift_code" class="form-control" type="text">
          <small>{{ $trans('8-11 characters (no dashes)') }}</small>
          <slot v-if="errors && errors['swift_code']">
            <div class="text-danger" v-for="error in errors['swift_code']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Account number')}} (*)</label>
          <input v-model="addFields.account_number" class="form-control" type="text">
          <small>{{ $trans('A 1-35 digit numbers. Contact your bank if you need help.') }}</small>
          <slot v-if="errors && errors['account_number']">
            <div class="text-danger" v-for="error in errors['account_number']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Account name')}} (*)</label>
          <input v-model="addFields.account_name" class="form-control" type="text">
          <small>{{ $trans('A 1-35 digit numbers. Contact your bank if you need help.') }}</small>
          <slot v-if="errors && errors['account_name']">
            <div class="text-danger" v-for="error in errors['account_name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="add_payout_bank" class="btn btn-primary">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Add')}}
        </button>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DepositBank",
  data:function (){
    return {
      addForm:false,
      addFields:{
        bank_code:'',
        swift_code:'',
        account_number:'',
        account_name:'',
      },
      alert:null,
      errors:{},
      current_currency:this.$root.$data.current_currency,
      process:false,
      filterQuery:{
          page:1,
      },
      deposit_accounts:null,
      updateFields:{},
      type:'bank',
      withdrawl_banks:null
    }
  },
  methods:{
    
    filter:function (){
        this.filterQuery.page = 1;
        this.get_payout_banks();
    },
    paginate: function (pageNum) {
        this.filterQuery.page = pageNum;
        this.get_payout_banks();
    },
    get_payout_banks:function () {
        axios({url: this.$root.$data.api_url + '/api/client/payoutbanks',params: this.filterQuery, method: 'GET'})
            .then(resp => {
                this.withdrawl_banks = resp.data;
            })
            .catch(err => {
                console.log(err)
            })
    },
    add_payout_bank: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null

        axios({url: this.$root.$data.api_url + '/api/client/payoutbank', data: this.addFields, method: 'POST'})
            .then(resp => {
                let error_code = resp.data.error_code;
                if( error_code == 0){
                    this.addForm = false;
                    this.get_payout_banks();
                }else{
                    this.alert = {
                        type: 'warning',
                        message: resp.data.message
                    }
                    this.errors = resp.data.errors || {};
                }
                this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    destroy:function (m) {
      var actionConfirm = confirm("Are you sure?");
      if (actionConfirm === false) {
          return false;
      }

      axios({url: this.$root.$data.api_url + '/api/client/payoutbanks/' + m._id, method: 'DELETE'})
          .then(() => {
            this.get_payout_banks();
          })
          .catch(err => {
            console.log(err)
          })
    },

  },
  computed:{
    payout_bank_supports:function (){
      let banks = this.$root.$data.global_settings.payout_support_banks
      if( ! banks){
        return  [];
      }
      let payout_bank_supports =[]

      for (const [key, value] of Object.entries(banks)) {
        let item ={
          id:key,
          text:value.name,
        }
        payout_bank_supports.push(item)
      }

      return payout_bank_supports
    },
  },
   mounted(){
    document.addEventListener("keydown", (e) => {
        if (e.keyCode == 27) {
            this.addForm = false;
        }
    });
  },
  created() {
    this.get_payout_banks()
  }
}
</script>

<style scoped>
  .stt-unlock {
    color: rgb(11, 122, 11);
  }
  .stt-lock {
    color:rgb(241, 35, 35);
  }
</style>