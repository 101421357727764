import { render, staticRenderFns } from "./IbOnline.vue?vue&type=template&id=19969ed8&scoped=true&"
import script from "./IbOnline.vue?vue&type=script&lang=js&"
export * from "./IbOnline.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19969ed8",
  null
  
)

export default component.exports