<template>
  <div v-if="zalo_report_data && zalo_statistic_data && zalo_statistic_data.transaction_revenue_chart && current_currency =='VND'"
       class="card momo-statistic">
    <div class="card-body">
      <h6>{{ $trans('Zalo Reports') }}</h6>
      <hr>
      <div class="item">
        <span class="title">{{ $trans('Revenue') }}</span>
        <span class="value">
                      <vue-numeric
                          currency-symbol-position="suffix"
                          :currency="current_currency"
                          v-bind:value="zalo_report_data.revenue"
                          v-bind:read-only="true"
                          v-bind:precision="$get_precision()">
                      </vue-numeric>
                    </span>
      </div>
      <div class="item">
        <span class="title">{{ $trans('Profit') }}</span>
        <span class="value">
                    <vue-numeric
                        currency-symbol-position="suffix"
                        :currency="current_currency"
                        v-bind:value="zalo_report_data.profit"
                        v-bind:read-only="true"
                        v-bind:precision="$get_precision()">
                      </vue-numeric>
                  </span>
      </div>
      <div class="item">
        <span class="title">{{ $trans('Transactions') }}</span>
        <span class="value">
                    <vue-numeric
                        currency-symbol-position="suffix"
                        currency=""
                        v-bind:value="zalo_report_data.total_success_transaction"
                        v-bind:read-only="true"
                        v-bind:precision="0">
                        </vue-numeric>
                       <span class="text-dark">
                         /
                         <vue-numeric
                             currency-symbol-position="suffix"
                             currency=""
                             v-bind:value="zalo_report_data.total_transaction"
                             v-bind:read-only="true"
                             v-bind:precision="0">
                       </vue-numeric>
                       </span>
                  </span>
      </div>
      <div class="item">
        <span class="title">AVG Time</span>
        <span class="value">
                        <vue-numeric
                            currency-symbol-position="suffix"
                            :currency="'Min'"
                            v-bind:value="zalo_report_data.avg_time * 60"
                            v-bind:read-only="true"
                            v-bind:precision="2">
                       </vue-numeric>
                    </span>
      </div>
      <slot
          v-if="zalo_report_data && zalo_report_data.revenue_chart_data && zalo_report_data.revenue_chart_data.length >1">
        <GChart
            type="LineChart"
            :data="zalo_report_data.revenue_chart_data"
            :options="zalo_statistic_data.transaction_revenue_chart.options"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ZaloReport",
  props:{
    filterby: String,
  },
  data: function () {
    return {
      current_currency: this.$root.current_currency,
      zalo_statistic_data: null,
      extra_zalo_transaction: 0,
      extra_zalo_success_transaction: 0,
      extra_zalo_profit: 0,
      extra_zalo_revenue: 0,
    }
  },
  methods: {
    zalo_statistic: function () {
      let filterQuery ={
        filterby:this._props.filterby
      }
      axios({url: this.$root.$data.api_url + '/api/client/statistic/zalo', params: filterQuery, method: 'GET'})
          .then(resp => {
            this.zalo_statistic_data = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
    this.zalo_statistic()
  },
  watch: {
    filterby: function() { // watch it
      this.zalo_statistic()
    }
  },
  computed:{
    zalo_report_data: function () {
      if (!this.zalo_statistic_data) {
        return [];
      }
      let data = this.zalo_statistic_data.results
      let revenue_chart_data = [
        ['Time', 'Revenue', 'Transactions']
      ]
      let total_transaction = 0;
      let total_success_transaction = 0;
      let profit = 0;
      let revenue = 0;
      let avg_time = 0;
      data.forEach((el) => {
        total_transaction = total_transaction + el.total_transaction;
        profit = profit + el.profit
        revenue = revenue + el.revenue
        total_success_transaction = total_success_transaction + el.total_success_transaction
        avg_time = avg_time + el.avg_time
        let item_revenue_chart_data = [
          el.vAxisTitle,
          el.revenue,
          el.total_transaction,
        ]
        revenue_chart_data.push(item_revenue_chart_data);
      })
      if( data.length >0){
        avg_time = avg_time/data.length
      }
      return {
        revenue_chart_data: revenue_chart_data,
        total_transaction: total_transaction + this.extra_zalo_transaction,
        profit: profit + this.extra_zalo_profit,
        revenue: revenue + this.extra_zalo_revenue,
        total_success_transaction: total_success_transaction + this.extra_zalo_success_transaction,
        avg_time: avg_time
      }
    },
  }
}
</script>

<style scoped>

</style>