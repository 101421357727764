<template>
    <div>
        <div class="sidebar-head">
          <router-link to="/">
            <img class="img-logo" :src="require('../assets/logo.png')" alt="">
          </router-link>
        </div>
        <div class="user-info">
            <router-link to="/profile">
                <span class="avatar-logo">
                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                </span>
                 <span class="info">
                    <span class="name">{{ user }}</span>
                </span>
            </router-link>
        </div>
      <div class="currency-form mb-1">
        <select @change="change_currency"  class="form-control">
          <option :selected="currency == current_currency" v-for="(currency,key) in currencies" v-bind:key="key" :value="currency">
            {{currency}}
          </option>
        </select>
      </div>
        <div class="sidebar-menu-content">
            <ul class="sidebar-menu">
              <li>
                <router-link to="/">
                  <span class="icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                  </span>
                  <span class="text">{{$trans('Dashboard')}}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/deposits">
                  <span class="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                  </span>
                  <span class="text">{{$trans('Deposits')}}</span>
                </router-link>
              </li>

              <li>
                <router-link to="/withdrawal">
                  <span class="icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="8 17 12 21 16 17"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"></path></svg>
                  </span>
                  <span class="text">{{$trans('Withdrawal')}}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/reports">
                  <span class="icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="12" y1="20" x2="12" y2="10"></line><line x1="18" y1="20" x2="18" y2="4"></line><line x1="6" y1="20" x2="6" y2="16"></line></svg>
                  </span>
                  <span class="text">{{$trans('Reports')}}</span>
                </router-link>
              </li>
              <li>
                  <router-link to="/settings">
                      <span class="icon">
                          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
                      </span>
                      <span class="text">{{$trans('Settings')}}</span>
                  </router-link>
              </li>
              <li class="menu-item">
                <a href="javascript:void(0)" @click="logout">
                   <span class="icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                  </span>
                  <span>{{$trans('Logout')}}</span>
                </a>
              </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Sidebar",
        data:function () {
            return {
              app_name:'M2AVN',

              current_currency:this.$root.current_currency
            }
        },
      methods:{
        logout: function () {
          this.$store.dispatch('logout')
              .then(() => {
                this.$router.push('/login')
              })
        },
        change_currency:function (event){
          this.$store.dispatch('changeCurrency', event.target.value)
        }
      },
      computed:{
          user:function () {
              return this.$store.getters.user
          },

          currencies:function (){
            return this.$root.$data.global_settings.currencies_support;
          },
      }
    }
</script>

<style scoped>

</style>