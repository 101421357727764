<template>
  <div v-if="ib_bank_enabled" class="card">
    <div class="card-body">
      <h6>{{ $trans('IB Bank Online') }}</h6>
      <hr>
      <div v-for="(bank,key) in ib_bank_enabled" :key="key" class="bank-item">
        <span class="name mr-auto">{{ bank.name }}</span>
        <span class="status">
                  <span v-if="bank.status == 1" class="mr-10 mt-0 badge badge-success">{{ $trans('Online') }}</span>
                  <span v-if="bank.status == 0" class="mr-0 mt-0 badge badge-dark">{{ $trans('Offline') }}</span>
                </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "IbOnline",
  data:function (){
    return {
      bank_status:null,
      current_currency: this.$root.current_currency,
    }
  },
  methods:{
    get_bank_status: function () {
      axios({url: this.$root.$data.api_url + '/api/status/bank', params: [], method: 'GET'})
          .then(resp => {
            this.bank_status = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
    this.get_bank_status()
  },
  computed:{
    bank_supports: function () {
      return this.$root.$data.global_settings.bank_supports;
    },
    ib_bank_enabled: function () {
      if (!this.bank_status) {
        return []
      }
      let ib_bank_enabled = this.bank_status[this.current_currency]
      let bank_supports = this.bank_supports
      let banks = []
      for (const property in bank_supports) {
        let item = {
          key: property,
          name: bank_supports[property],
        }
        if (property in ib_bank_enabled) {
          item.status = 1
          banks.push(item)
        } else {
          item.status = 0
        }


      }
      return banks
    },
  }
}
</script>

<style scoped>

</style>