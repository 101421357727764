<template>
  <div v-if="ib_report_data && ib_statistic_data && ib_statistic_data.transaction_revenue_chart" class="card momo-statistic">
    <div class="card-body">
      <h6>{{ $trans('IB Reports') }}</h6>
      <hr>
      <div class="item">
        <span class="title">{{ $trans('Revenue') }}</span>
        <span class="value">
          <vue-numeric
              currency-symbol-position="suffix"
              :currency="current_currency"
              v-bind:value="ib_report_data.revenue"
              v-bind:read-only="true"
              v-bind:precision="$get_precision()">
          </vue-numeric>
        </span>
      </div>
      <div class="item">
        <span class="title">{{ $trans('Profit') }}</span>
        <span class="value">
          <vue-numeric
              currency-symbol-position="suffix"
              :currency="current_currency"
              v-bind:value="ib_report_data.profit"
              v-bind:read-only="true"
              v-bind:precision="$get_precision()">
            </vue-numeric>
        </span>
      </div>
      <div class="item">
        <span class="title">{{ $trans('Transactions') }}</span>
        <span class="value">
          <vue-numeric
              currency-symbol-position="suffix"
              currency=""
              v-bind:value="ib_report_data.total_success_transaction"
              v-bind:read-only="true"
              v-bind:precision="0">
              </vue-numeric>
             <span class="text-dark">
               /
               <vue-numeric
                   currency-symbol-position="suffix"
                   currency=""
                   v-bind:value="ib_report_data.total_transaction"
                   v-bind:read-only="true"
                   v-bind:precision="0">
             </vue-numeric>
             </span>
        </span>
      </div>
      <div class="item">
        <span class="title">AVG Time</span>
        <span class="value">
            <vue-numeric
                currency-symbol-position="suffix"
                :currency="'Min'"
                v-bind:value="ib_report_data.avg_time * 60"
                v-bind:read-only="true"
                v-bind:precision="2">
           </vue-numeric>
        </span>
      </div>
      <slot
          v-if="ib_report_data && ib_report_data.revenue_chart_data && ib_report_data.revenue_chart_data.length >1">
        <GChart
            type="LineChart"
            :data="ib_report_data.revenue_chart_data"
            :options="ib_statistic_data.transaction_revenue_chart.options"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "IbReport",
  props:{
    filterby: String,
  },
  data:function (){
    return {
      ib_statistic_data: null,
      extra_ib_transaction: 0,
      extra_ib_success_transaction: 0,
      extra_ib_profit: 0,
      extra_ib_revenue: 0,
      current_currency: this.$root.current_currency,

    }
  },
  methods:{
    ib_statistic: function () {
      let filterQuery ={
        filterby:this._props.filterby
      }
      axios({url: this.$root.$data.api_url + '/api/client/statistic/ib', params: filterQuery, method: 'GET'})
          .then(resp => {
            this.ib_statistic_data = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
    this.ib_statistic()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('deposit-channel');
    channel.bind('deposit-event', (e) => {
      if (this.current_currency == e.currency && (this.filterby == 'today' || this.filterby == 'month' || this.filterby == 'year')) {
        if (e.action == 'created') {

          if (e.transaction.method == 'MOMO') {
            this.extra_ib_transaction = this.extra_ib_transaction + 1
          }
        } else {
          let status = e.transaction.status
          let method = e.transaction.method
          if (status == 1 && method == 'MOMO') {
            this.extra_ib_success_transaction = this.extra_ib_success_transaction + 1
            this.extra_ib_profit = this.extra_ib_profit + e.transaction.fee
            this.extra_ib_revenue = this.extra_ib_revenue + e.transaction.amount
          }
        }
      }

    });

  },
  watch: {
    filterby: function() { // watch it
      this.ib_statistic()
    }
  },
  computed:{
    ib_report_data: function () {
      if (!this.ib_statistic_data) {
        return [];
      }
      let data = this.ib_statistic_data.results
      let revenue_chart_data = [
        ['Time', 'Revenue', 'Transactions']
      ]
      let total_transaction = 0;
      let total_success_transaction = 0;
      let profit = 0;
      let revenue = 0;
      let avg_time = 0;
      data.forEach((el) => {
        total_transaction = total_transaction + el.total_transaction;
        profit = profit + el.profit
        revenue = revenue + el.revenue
        total_success_transaction = total_success_transaction + el.total_success_transaction
        avg_time = avg_time + el.avg_time
        let item_revenue_chart_data = [
          el.vAxisTitle,
          el.revenue,
          el.total_transaction,
        ]
        revenue_chart_data.push(item_revenue_chart_data);

      })
      return {
        revenue_chart_data: revenue_chart_data,
        total_transaction: total_transaction + this.extra_ib_transaction,
        profit: profit + this.extra_ib_profit,
        revenue: revenue + this.extra_ib_revenue,
        total_success_transaction: total_success_transaction + this.extra_ib_success_transaction,
        avg_time: avg_time /data.length
      }
    },
  }
}
</script>

<style scoped>

</style>