<template>
  <div class="withdrawal row">
    <div class="col-sm-3">
      <div v-if="profile" class="card">
        <div class="card-body">
          <table  class="table table-hover table-custom spacing5">
            <tr>
              <th>{{$trans('Username')}}:</th>
              <td>{{ profile.username }}</td>
            </tr>
            <tr>
              <th>{{$trans('Name')}}:</th>
              <td>{{ profile.name }}</td>
            </tr>
            <tr>
              <th>{{$trans('Email')}}:</th>
              <td>{{ profile.email }}</td>
            </tr>
            <tr v-if="profile.wallet">
              <th class="w200">{{$trans('Balance')}}:</th>
              <td class="text-success">
                <vue-numeric currency-symbol-position="suffix"
                             v-bind:currency="current_currency"
                             v-model="profile.wallet.amount"
                             v-bind:read-only="true"
                             v-bind:precision="0"></vue-numeric>
              </td>
            </tr>

          </table>
          <button @click="addForm = true" class="btn btn-primary btn-sm">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
            {{$trans('New Withdrawal')}}
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <div class="card">
        <div class="card-body">
          <div class="card-header">
            <h2 class="mr-auto ">{{$trans('Withdrawal History')}}</h2>
          </div>
          <slot v-if="withdrawalHistory && withdrawalHistory.data.length > 0">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>{{$trans('Bank Name')}}</th>
                  <th>{{$trans('Bank Info')}}</th>
                  <th>{{$trans('Amount Payout')}}</th>
                  <th>{{$trans('Status')}}</th>
                  <th>{{$trans('Actions')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(withdrawal,index) in withdrawalHistory.data" v-bind:key="index">
                  <td>
                    <slot v-if="withdrawal.bank">{{ withdrawal.bank.name }}</slot>
                  </td>
                  <td>
                    <slot v-if="withdrawal.bank">{{ withdrawal.bank.account_name }} - {{ withdrawal.bank.account_number }}</slot>
                  </td>
                  <td>
                    <vue-numeric
                        currency-symbol-position="suffix"
                        :currency="current_currency"
                        v-bind:value="withdrawal.amount"
                        v-bind:read-only="true"
                        v-bind:precision="0">
                    </vue-numeric>
                  </td>
                  <td>
                  <span v-if="withdrawal.status==1"
                        class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Success') }}</span>
                    <span v-if="withdrawal.status==0"
                          class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Hold') }}</span>
                    <span v-if="withdrawal.status==-1"
                          class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Reject') }}</span>
                  </td>
                  <td class="text-right">
                <span class="tn-detail" @click="detail(withdrawal)" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg"
                       width="24" height="24"
                       viewBox="0 0 24 24" fill="none"
                       stroke="currentColor"
                       stroke-width="2"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       class="feather feather-external-link"><path
                      d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline
                      points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </slot>
          <slot v-else>
            {{$trans('No data')}}
          </slot>
          <paginate v-if="withdrawalHistory && withdrawalHistory.data.length > 0 && withdrawalHistory.last_page"
                    :page-count="withdrawalHistory.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>

        </div>

      </div>
    </div>
    <modal v-if="addForm" v-bind:width="'100vh'" v-bind:height="'700px'">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Withdrawal')}}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2"
                 fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{$trans('The safety and security of your bank account information is protected by netpay88.com')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Amount')}} (*)</label>
          <vue-numeric class="form-control"
                       v-bind:currency-symbol-position="'suffix'"
                       v-bind:currency="current_currency"
                       v-model="addFields.amount"
                       v-bind:read-only="false"
                       v-bind:precision="0">
          </vue-numeric>
          <slot v-if="errors && errors['amount']">
            <div class="text-danger" v-for="error in errors['amount']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Bank name')}} (*)</label>
          <Select2 v-model="addFields.payout_bank_id"  :options="bank_supports"  />
          <slot v-if="errors && errors['payout_bank_id']">
            <div class="text-danger" v-for="error in errors['payout_bank_id']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="add_withdrawal" class="btn btn-primary">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Add')}}
        </button>
      </slot>
    </modal>
    <modal v-if="showModal" v-bind:height="'100vh'" v-bind:width="'700px'">
      <slot slot="body">
        <h5>
          {{ $trans('Transaction info') }}
          <button @click="showModal=false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2"
                 fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <hr>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-hover table-custom spacing5">
                <tbody>
                <tr>
                  <th>{{ $trans('Ref Number') }}</th>
                  <td>{{ withdrawal_detail.ref_number }}</td>
                </tr>
                <tr>
                  <th>{{ $trans('Status') }}</th>
                  <td>
                    <slot v-if="withdrawal_detail._id != null">
                      <span v-if="withdrawal_detail.status==1"
                            class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Success') }}</span>
                      <span v-if="withdrawal_detail.status==0"
                            class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Hold') }}</span>
                      <span v-if="withdrawal_detail.status==-1"
                            class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Reject') }}</span>
                    </slot>
                  </td>
                </tr>
                <tr>
                  <th>{{ $trans('Amount') }}</th>
                  <td class="text-success">
                    <vue-numeric
                        currency-symbol-position="suffix"
                        :currency="current_currency"
                        v-bind:value="withdrawal_detail.amount"
                        v-bind:read-only="true"
                        v-bind:precision="0">
                    </vue-numeric>
                  </td>
                </tr>
                <tr>
                  <th>{{ $trans('Bank Name') }}</th>
                  <td>
                    <slot v-if="withdrawal_detail.bank">{{withdrawal_detail.bank.name}}</slot>
                  </td>
                </tr>
                <tr>
                  <th>{{ $trans('Bank Swiftcode') }}</th>
                  <td >
                    <slot v-if="withdrawal_detail.bank">{{withdrawal_detail.bank.swiftcode}}</slot>
                  </td>
                </tr>
                <tr>
                  <th>{{ $trans('Account Name') }}</th>
                  <td >
                    <slot v-if="withdrawal_detail.bank">{{withdrawal_detail.bank.account_name}}</slot>
                  </td>
                </tr>
                <tr>
                  <th>{{ $trans('Account Number') }}</th>
                  <td >
                    <slot v-if="withdrawal_detail.bank">{{withdrawal_detail.bank.account_number}}</slot>
                  </td>
                </tr>
                <tr>
                  <th>{{ $trans('Created') }}</th>
                  <td >
                    <slot v-if="withdrawal_detail.bank">{{withdrawal_detail.created_at }}</slot>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
name: "Withdrawal",
  data:function (){
    return {
      addForm:false,
      profile: null,
      addFields:{
        amount:0,
        payout_bank_id:''
      },
      alert:null,
      errors:{},
      filterQuery:{
        page:1,
      },
      showModal:false,
      current_currency:this.$root.current_currency,
      payout_banks:this.$root.$data.payout_support_banks,
      process:false,
      withdrawalBanks:null,
      withdrawal_detail:null,
      withdrawalHistory:null
    }
  },
  methods:{
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.get_withdrawal_history();
    },
    get_info: function () {
      axios({url: this.$root.$data.api_url + '/api/profile', method: 'GET'})
          .then(resp => {
            this.profile = resp.data.user;
          })
          .catch(err => {
            console.log(err)
          })
    },
    get_withdrawal_banks:function (){
      axios({url: this.$root.$data.api_url + '/api/client/withdrawal_banks', method: 'GET'})
          .then(resp => {
            this.withdrawalBanks = resp.data;
          })
          .catch(err => {
            console.log(err)
          })

    },
    get_withdrawal_history:function(){
      axios({url: this.$root.$data.api_url + '/api/client/withdrawal_history',params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.withdrawalHistory = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    add_withdrawal:function (){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null

        axios({url: this.$root.$data.api_url + '/api/client/withdrawal', data: this.addFields, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.addForm = false;
                this.get_withdrawal_history();
                this.profile.wallet = this.profile.wallet - this.addFields.amount;
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },

    detail:function (withdrawal) {
      this.showModal = true;
      this.withdrawal_detail = withdrawal
    }
  },
  computed:{
    bank_supports:function (){
      if(!this.withdrawalBanks){
        return  [];
      }
      let banks = this.withdrawalBanks
      let bank_supports =[]

      banks.forEach((value)=>{
        let item ={
          id:value._id,
          text:value.name +' - '+value.account_number
        }
        bank_supports.push(item)
      })

      return bank_supports
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.showModal = false;
        this.addForm = false;
      }
    });
  },
  created() {
    this.get_info();
    this.get_withdrawal_banks();
    this.get_withdrawal_history();

    // Real time
    // Real time
    const userData = JSON.parse(localStorage.getItem('user_data'));
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('payout-channel');
    channel.bind('payout-event',  (e)=> {
      if( this.current_currency == e.currency && userData._id == e.transaction.user_id ){
        if (this.withdrawalHistory && this.withdrawalHistory.data){
          if( e.action=='created'){
            this.withdrawalHistory.data.unshift(e.transaction);
          }else{
            var arr = this.withdrawalHistory.data;
            var tmp_arr = [];
            var current_id = e.transaction._id;
            arr.forEach(function(el, index) {
              if(current_id == el._id){
                tmp_arr[index] = e.transaction;
              }else{
                tmp_arr[index] = el;
              }
            });
            this.withdrawalHistory.data = tmp_arr;
          }
        }
      }
    });
  }
}
</script>

<style scoped>
  .mr-auto{
    display: inline-block;
  }
  .mr-withdrawal{
    margin-left: 10px;
    margin-bottom: 0.5rem;
  }
  .withdrawal{
    display: flex;
  }

</style>